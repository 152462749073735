import {expressQuerySelector} from "../common/html";

export enum PageLogStyle {
	Error,
	WarningBlue,
	WarningOrange,
	Success
}

export interface IPageLog {
	readonly updateMessageAndStyle: (logStyle: PageLogStyle, description: string, overrideScrollTo?: boolean) => void;
	readonly hideLog: () => void;
	readonly scrollTo: () => void;
}

export function createPageLog(containerEl: HTMLElement) {

	const iconEl = expressQuerySelector<HTMLElement>(containerEl, '.technical-top-message-icon', true);
	const descriptionEl = expressQuerySelector<HTMLElement>(containerEl, '.technical-top-message-description', true);

	const getClassesByPrefix = (el: HTMLElement, prefix: string) => {
		const classes: string[] = [].slice.call(el.classList);
		return classes.filter(c => c.indexOf(prefix) > -1);
	};

	const LOG_PREFIX = "m-page-log--";
	const ICON_PREFIX = "icon-";

	const getLogIconAndType = (logStyle: PageLogStyle) => {
		switch (logStyle) {
			case PageLogStyle.Error:
				return {
					type: "m-page-log--error",
					icon: "icon-error-filled"
				};
			case PageLogStyle.Success:
				return {
					type: "m-page-log--validation",
					icon: "icon-succes"
				};
			case PageLogStyle.WarningBlue:
				return {
					type: "m-page-log--info-blue",
					icon: "icon-info"
				};
			case PageLogStyle.WarningOrange:
				return {
					type: "m-page-log--info-orange",
					icon: "icon-info"
				};
			default:
				return {
					type: "m-page-log--error",
					icon: "icon-error-filled"
				};
		}
	};

	const showLog = () => containerEl.classList.remove("u-hide");

	const hideLog = () => containerEl.classList.add("u-hide");

	const scrollTo = () => window.scrollTo({top: getOffsetTop(containerEl), behavior: 'smooth'});

	const getOffsetTop = (offsetElemet: HTMLElement) => {
		let offsetTop = 0;
		let elem: HTMLElement | undefined = offsetElemet;
		do {
			if (elem.offsetTop > 0) {
				offsetTop += elem.offsetTop;
			}

			elem = elem.offsetParent as HTMLElement | undefined;
		} while (elem);
		return offsetTop;
	};

	const updateMessageAndStyle = (logStyle: PageLogStyle, description: string, overrideScrollTo = false) => {
		const disableScroll = description === descriptionEl.innerHTML;
		containerEl.classList.remove(...getClassesByPrefix(containerEl, LOG_PREFIX));
		iconEl.classList.remove(...getClassesByPrefix(iconEl, ICON_PREFIX));

		const {type, icon} = getLogIconAndType(logStyle);

		containerEl.classList.add(type);
		iconEl.classList.add(icon);

		descriptionEl.innerHTML = description;
		showLog();
		if (!disableScroll || overrideScrollTo)
			scrollTo();
	};

	return {
		updateMessageAndStyle,
		hideLog,
		scrollTo
	} as IPageLog;
}
