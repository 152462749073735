import { createGeneralPageEventsAndComponents } from "../components/page-general";
import { CreateLoginRegister } from "../components/login-register";
import { expressQuerySelector } from "../common/html";

window.addEventListener('DOMContentLoaded', () => {
	createGeneralPageEventsAndComponents();

	const icon = expressQuerySelector(document, '.technical-close-icon', false);
	if (icon && window.parent) {
		icon.addEventListener('click', _ => {
			window.parent.postMessage({ command: 'CloseLoginPopup' }, '*');
		});
	}

	const pageLogEl = expressQuerySelector<HTMLElement>(document, '.technical-top-message-log', false);
	CreateLoginRegister(null, { pageLogEl });
});
